var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"af4322f162378ec9093a0bd35fa1d264cae8e1b6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://99343898ef4f4d169c7980baf1ae3d26@o1057376.ingest.sentry.io/6044121',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: 'pnp@' + process.env.NEXT_PUBLIC_BUILD_VERSION,
  environment: process.env.NEXT_PUBLIC_STAGE_VERSION,
  tracesSampleRate: 1.0,
  autoSessionTracking: false
});
